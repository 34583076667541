@import '../../resources/vars';
@import '../../resources/mixins';

.container {
  display: flex;
  align-items: center;

  height: var(--coach-bars-row-height);
  width: var(--coach-bars-row-width);
  padding: var(--coach-bars-row-padding);
  margin: var(--coach-bars-row-margin);
  background: var(--coach-bars-row-background);
  border: var(--coach-bars-row-border);
  border-radius: var(--coach-bars-row-border-radius);
  box-shadow: var(--coach-bars-row-box-shadow);
}

.value {
  flex-basis: 50px;
  flex-grow: 0;

  color: var(--coach-bars-value-colour);
  font-size: var(--coach-bars-value-font-size);
  font-family: var(--coach-bars-value-font-family);
  font-weight: var(--coach-bars-value-font-weight);
  font-style: var(--coach-bars-value-font-style);
  line-height: var(--coach-bars-value-line-height);
  letter-spacing: var(--coach-bars-value-letter-spacing);
  text-transform: var(--coach-bars-value-text-transform);
  margin: var(--coach-bars-value-margin);
  padding: var(--coach-bars-value-padding);
}

.bar {

  --app-custom-progress-bar-height: var(--coach-bars-bar-height);
  --app-custom-progress-bar-width: var(--coach-bars-bar-width);
  --app-custom-progress-bar-padding: var(--coach-bars-bar-padding);
  --app-custom-progress-bar-margin: var(--coach-bars-bar-margin);
  --app-custom-progress-bar-background: var(--coach-bars-bar-background);
  --app-custom-progress-bar-border: var(--coach-bars-bar-border);
  --app-custom-progress-bar-border-radius: var(--coach-bars-bar-border-radius);
  --app-custom-progress-bar-box-shadow: var(--coach-bars-bar-box-shadow);

  --app-custom-progress-filler-background: var(--coach-bars-bar-value-background);
  --app-custom-progress-filler-border: var(--coach-bars-bar-value-border);
  --app-custom-progress-filler-border-radius: var(--coach-bars-bar-value-border-radius);
  --app-custom-progress-filler-box-shadow: var(--coach-bars-bar-value-box-shadow);

  --app-custom-progress-label-margin: var(--coach-bars-label-margin);
  --app-custom-progress-label-padding: var(--coach-bars-label-padding);
}

.winner {
  --app-custom-progress-filler-background: var(--coach-bars-bar-value-winner-background);
  --app-custom-progress-filler-border: var(--coach-bars-bar-value-winner-border);
  --app-custom-progress-filler-border-radius: var(--coach-bars-bar-value-winner-border-radius);
  --app-custom-progress-filler-box-shadow: var(--coach-bars-bar-value-winner-box-shadow);

  --app-custom-progress-label-color: var(--coach-bars-label-winner-colour);
  --app-custom-progress-label-font-size: var(--coach-bars-label-winner-font-size);
  --app-custom-progress-label-font-family: var(--coach-bars-label-winner-font-family);
  --app-custom-progress-label-font-weight: var(--coach-bars-label-winner-font-weight);
  --app-custom-progress-label-font-style: var(--coach-bars-label-winner-font-style);
  --app-custom-progress-label-line-height: var(--coach-bars-label-winner-line-height);
  --app-custom-progress-label-letter-spacing: var(--coach-bars-label-winner-letter-spacing);
  --app-custom-progress-label-text-transform: var(--coach-bars-label-winner-text-transform);
}
